import { Config } from './config/config';
import { NotifyEmitter } from './NotifyEmitter';
import { ChatSettings } from './Settings';

export class ChatIFrame implements NotifyEmitter {
  private id: string;
  private settings: ChatSettings;
  private element?: HTMLIFrameElement;

  constructor(id: string, origin: string, settings: ChatSettings, container: HTMLElement) {
    this.id = id;
    this.settings = settings;

    this.onMessage = this.onMessage.bind(this);

    window.addEventListener('message', this.onMessage);

    this.element = document.createElement('iframe');

    this.element.id = `nativechat-iframe-${this.id}`;
    this.element.style.width = '100%';
    this.element.style.height = '100%';
    this.element.style.border = 'none';

    if (settings.showLocationPicker !== false) {
      this.element.setAttribute('allow', 'geolocation');
    }

    container.appendChild(this.element);
    this.element.src = `${Config.webchatUrl}?id=${encodeURIComponent(this.id)}&origin=${encodeURIComponent(origin)}`;
  }

  public dispose() {
    window.removeEventListener('message', this.onMessage);

    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    this.element = undefined;
  }

  public notify(message: string | object, origin?: string) {
    if (this.element && this.element.contentWindow) {
      this.element.contentWindow.postMessage(message, origin || Config.webchatOrigin);
    }
  }

  private onMessage = (event: MessageEvent) => {
    if (event.origin === Config.webchatOrigin &&
      event.data.id === this.id &&
      event.data.state === 'loaded') {
      this.notify(this.settings, event.origin);
    }
  }
}
