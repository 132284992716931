import { InlineChat } from './InlineChat';
import { ModalChat } from './ModalChat';
import { NotifyEmitter } from './NotifyEmitter';
import { Display, SDKSettings } from './Settings';

export class NativeChatSDK {
  public chats: { [key: string]: NotifyEmitter } = {};

  public load(settings: SDKSettings) {
    this.removeChat(settings.id);

    switch (settings.display.mode) {
      case Display.Inline: {
        const chat = new InlineChat(settings.id, settings.origin, settings.chat, settings.display.containerId);
        this.chats[settings.id] = chat;
        break;
      }
      case Display.Modal: {
        const chat = new ModalChat(settings, settings.display.launcher);
        this.chats[settings.id] = chat;
        break;
      }
    }
  }

  public showDebugLog(id: string) {
    this.chats[id].notify({ type: 'show-debug-log', value: true });
  }

  public hideDebugLog(id: string) {
    this.chats[id].notify({ type: 'show-debug-log', value: false });
  }

  public removeChat(id: string): void {
    if (this.chats[id]) {
      this.chats[id].dispose();
      delete this.chats[id];
    }
  }
}
